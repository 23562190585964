import React from 'react';
import useResponsiveQuery from 'utils/useResponsiveQuery';
import { AI_ROUTE } from 'components/constants';
import { LinkWithQuery } from 'components/common/LinkWithQuery';
import styles from './styles.module.scss';

function AISection() {
  const isMobile = useResponsiveQuery();
  const mainTitleContent = 'Your powerful AI engagement machine';
  const mainTextContent =
    'Supercharge engagement across your websites and applications with Storycards AI. Welcome to the forefront of engagement technology.';
  const CTALabel = 'Discover Storycards AI';
  return (
    <section className={`${styles.infoSection} w-full relative text-center md:text-left`}>
      <h2 className={`${styles.mainTitle} font-semibold`}>{mainTitleContent}</h2>
      <p className={`${styles.mainText} `}>{mainTextContent}</p>
      <LinkWithQuery to={AI_ROUTE} className={styles.CTAButton}>
        {CTALabel}
      </LinkWithQuery>
      <div className={`${styles.videoWrapper}`}>
        <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
          <iframe
            src="https://player.vimeo.com/video/911942395?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}
            title="Storycards AI"
          ></iframe>
        </div>
        <script src="https://player.vimeo.com/api/player.js"></script>
      </div>
    </section>
  );
}

export default AISection;
