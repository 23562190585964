import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { NavLink, useLocation } from 'react-router-dom';
import cn from 'classnames';

import useClickOutside from 'utils/useClickOutside';
import useResponsiveQuery from 'utils/useResponsiveQuery';
import ChevronIcon from 'components/common/icons/ChevronIcon';

import css from './styles.module.scss';
import cssHeader from '../styles.module.scss';

export type DropdownId = 'product' | 'analytics' | 'solutions' | undefined;

interface MenuItemType {
  label: string;
  url: string;
}

type Props = {
  title: string;
  titleClassName?: string;
  dropdownLinks: MenuItemType[];
  invertColors?: boolean;
  id?: DropdownId;
  activeDropdownId?: string;
  onClick?: React.Dispatch<React.SetStateAction<DropdownId>>;
};

function DropdownMenu({ title, titleClassName, dropdownLinks, invertColors, ...props }: Props) {
  const isMobile = useResponsiveQuery();
  const isOpen = props.activeDropdownId !== undefined && props.id === props.activeDropdownId;
  const toggleMenu = () => props.onClick?.((id) => (id === props.id ? undefined : props.id));
  const { search } = useLocation();

  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const menuRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const menuNode = menuRef.current;
    const dropdownNode = dropdownRef.current;

    if (!menuNode || !dropdownNode || !isOpen || !isMobile) {
      return;
    }

    const initialHeight = menuNode.clientHeight;
    const dropdownHeight = dropdownNode.clientHeight;

    gsap.to(menuNode, { height: initialHeight + dropdownHeight, duration: 0.1 });
    return () => {
      gsap.to(menuNode, { height: initialHeight, duration: 0.1 });
    };
  }, [isOpen, isMobile]);

  useClickOutside(menuRef, () => {
    if (isOpen && !isMobile) toggleMenu();
  });

  if (isMobile) {
    return (
      <div className={cn(css.menuMobile, 'relative', { [css.isOpen]: isOpen })} onClick={toggleMenu} ref={menuRef}>
        <div className={titleClassName}>
          {title}
          <ChevronIcon className={css.dropdownIcon} />
        </div>
        <div
          ref={dropdownRef}
          className={cn(
            css.dropdown,
            'flex',
            'flex-col',
            'absolute',
            'duration-100',
            isOpen ? 'visible opacity-100' : 'invisible opacity-0',
          )}
        >
          {dropdownLinks.map((item) => (
            <NavLink key={item.url} to={`${item.url}${search}`} activeClassName={cssHeader.navLinkActive}>
              {item.label}
            </NavLink>
          ))}
        </div>
      </div>
    );
  }

  // *** Desktop ***
  return (
    <div className={cn(css.menuDesktop, 'flex items-center relative')} onClick={toggleMenu} ref={menuRef}>
      <div className={titleClassName}>
        {title}
        <ChevronIcon className={css.dropdownIcon} />
      </div>
      <div
        className={cn(
          css.dropdown,
          'flex flex-col absolute duration-100',
          invertColors ? 'bg-black text-white' : 'bg-white text-black',
          isOpen ? 'visible opacity-100' : 'invisible opacity-0',
        )}
      >
        {dropdownLinks.map((item) => (
          <NavLink key={item.url} to={`${item.url}${search}`} activeClassName={cssHeader.navLinkActive}>
            {item.label}
          </NavLink>
        ))}
      </div>
    </div>
  );
}

export default DropdownMenu;
