import React, { useEffect, useRef } from 'react';
import throttle from 'lodash.throttle';
import { gsap } from 'gsap';
import cn from 'classnames';
import i1 from 'assets/images/portfolio/item1.jpeg';
import i2 from 'assets/images/portfolio/item2.jpeg';
import i3 from 'assets/images/portfolio/item3.jpeg';
import i4 from 'assets/images/portfolio/item4.jpeg';
import i5 from 'assets/images/portfolio/item5.jpeg';
import i6 from 'assets/images/portfolio/item6.jpeg';
import i7 from 'assets/images/portfolio/item7.jpeg';
import i8 from 'assets/images/portfolio/item8.jpeg';
import styles from './styles.module.scss';
import useResponsiveQuery from '../../../utils/useResponsiveQuery';

const itemsData = [
  { src: i1, link: 'https://stories.sc/templates/mcdonalds/' },
  { src: i2, link: 'https://stories.sc/storycards/FSna61ebe/' },
  { src: i3, link: 'https://stories.sc/templates/lacoste/' },
  { src: i4, link: 'https://inspiration.storycards.com/Starbucks/' },
  { src: i5, link: 'https://stories.sc/templates/samsung/' },
  { src: i6, link: 'https://stories.sc/templates/Bigi/' },
  { src: i7, link: 'https://stories.sc/storycards/V9Hdk3pk0/' },
  { src: i8, link: 'https://stories.sc/templates/tiktok-for-startups/' },
];

const items = [
  { className: styles.portfolioItemWide, ...itemsData[0] },
  { className: styles.portfolioItemTall, ...itemsData[1] },
  { className: styles.portfolioItemTall, ...itemsData[2] },
  { className: styles.portfolioItemWide, ...itemsData[3] },
  { className: styles.portfolioItemWide, ...itemsData[4] },
  { className: styles.portfolioItemTall, ...itemsData[5] },
  { className: styles.portfolioItemWide, ...itemsData[6] },
  { className: styles.portfolioItemWide, ...itemsData[7] },
];

const itemsMobile = [
  { className: styles.portfolioItemWide, ...itemsData[0] },
  { className: styles.portfolioItemTall, ...itemsData[1] },
  { className: styles.portfolioItemTall, ...itemsData[2] },
  { className: styles.portfolioItemWide, ...itemsData[3] },
  { className: styles.portfolioItemWide, ...itemsData[6] },
  { className: styles.portfolioItemTall, ...itemsData[4] },
  { className: styles.portfolioItemTall, ...itemsData[5] },
  { className: styles.portfolioItemWide, ...itemsData[7] },
];

const Portfolio = () => {
  const isMobile = useResponsiveQuery();
  const gridRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const gridNode = gridRef.current;

    if (!gridNode) {
      return;
    }

    const onMouseMove = throttle(({ pageX }: MouseEvent) => {
      const pageCenter = window.innerWidth / 2;
      const relativePosition = (pageCenter - pageX) / pageCenter; // 1 -> -1
      const xPercent = relativePosition * 3;
      gsap.to(gridNode, { xPercent, ease: 'power1.out' });
    }, 1000 / 16);

    const onMouseLeave = () => {
      onMouseMove.cancel();
      gsap.killTweensOf(gridNode);
      gsap.to(gridNode, { xPercent: 0, ease: 'sine.out' });
    };

    if (!isMobile) {
      gridNode.addEventListener('mousemove', onMouseMove);
      gridNode.addEventListener('mouseleave', onMouseLeave);
    }

    return () => {
      gridNode.removeEventListener('mousemove', onMouseMove);
      gridNode.removeEventListener('mouseleave', onMouseLeave);
    };
  }, [isMobile]);

  return (
    <div className={styles.portfolio}>
      <div className={cn(styles.portfolioHeader, 'text-center')}>
        <h3 className="font-semibold">Made in Storycards</h3>
        <p>We’re very proud of the incredible content Storycards users create. browse for inspiration</p>
        <a
          href="https://inspire.storycards.com"
          target="_blank"
          rel="noopener noreferrer"
          className={cn(styles.inspireButton, 'rounded-full')}
        >
          Get Inspired
        </a>
      </div>
      <div className={cn(styles.portfolioItems, 'flex', 'justify-center', 'flex-wrap')} ref={gridRef}>
        {(isMobile ? itemsMobile : items).map((item) => (
          <div className={styles.portfolioItem} key={item.link}>
            <div className={item.className} style={{ backgroundImage: `url(${item.src})` }}>
              {/* eslint-disable-next-line react/jsx-no-target-blank */}
              <a
                href={item.link}
                target="_blank"
                rel="noopener noreferrer nofollow"
                className={cn('flex', 'justify-center', 'items-center')}
                title="Open external link"
              >
                <svg
                  width="47px"
                  height="45px"
                  viewBox="0 0 47 45"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                >
                  <g transform="translate(-861.000000, -5878.000000)">
                    <polygon
                      fill="#FFF"
                      points="908 5878 908 5908.78659 901.125 5908.786 901.125 5889.158 865.861047 5923 861 5918.33513 896.155 5884.597 875.918 5884.597 875.918726 5878"
                    />
                  </g>
                </svg>
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Portfolio;
